import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Nosotros" keywords={[]} />

      <article className="about-us-content-body page-template no-image">
        <div className="post-content-body">
          <h1 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            Sé parte del impacto que hacemos en el mundo ¡conocé y compartí nuestra visión!
          </h1>
          <div className="about-us-first-container">
            <div className="m3d-us-image transition-fade-1s full-image-mobile">
              <Img
                fluid={data.andreyPhoto.childImageSharp.fluid}
                fadeIn={true}
              />
            </div>

            <div className="about-us-history">
              <p>
                Arq. Andrey Jiménez, graduado de la Universidad de Costa Rica en
                2020 miembro del Colegio Federado de Ingenieros y Arquitectos de
                Costa Rica, participó del{" "}
                <a href="https://www.plataformaarquitectura.cl/cl/794261/los-estudiantes-de-easa-2016-transforman-nida-en-lituania-con-una-serie-de-instalaciones?ad_source=search&ad_medium=search_result_all" rel="noreferrer" target="_blank">
                  EASA (European Architecture Student Assembly) 2016
                </a>{" "}
                en Nida Lituania, miembro de la asociación de estudiantes de la Escuela de Arquitectura de la UCR periodo 2015-2016, representante estudiantil de la carrera de Lic. en Arquitectura ante la Facultad de ingeniería y la Universidad de Costa Rica periodo 2015-2017. Ha desempeñado labores en distintas empresas desde 2014 elaborando proyectos de tipo residencial, vacacionales tipo Airbnb, comerciales y hotelería. English Spoken.
              </p>
              <p>
                Andrey arquitecto es un taller de arquitectura creativa e innovadora que busca ir más allá de la obra misma, donde diseñamos estrategias de negocio, experiencias personalizadas, tácticas del manejo urbano y soluciones arquitectónicas exclusivas.
              </p>
              <p>
                Ubicados en la ciudad de San Ramón de Alajuela en Costa Rica, nos proyectamos como una oficina de talla mundial que toma los desafíos actuales y los convierte en oportunidades para un mejor futuro, de la mano de profesionales en todos los campos pertinentes.
              </p>
              <p>
                La experiencia sensorial y personalización de nuestros proyectos es el pilar que fundamenta la sutil tarea de crear arquitectura.
              </p>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    andreyPhoto: file(relativePath: { eq: "andrey-photo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
